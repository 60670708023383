import { useState } from 'react';
import { useTrackWithDriftAndSegment } from '@peloton/drift/utils';
import {
  useOpenCartPanel,
  useSetIsCartLoading,
} from '@ecomm/cart-next/context/CartContext';
import useIsReferralSession from '@ecomm/cart-next/hooks/useIsReferralSession';
import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useAddCfuToCartAnalytics from '@ecomm/commercetools/hooks/useAddCfuToCartAnalytics';
import { useCreateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/CreateCart.generated';
import { GetShopCartJupiterDocument } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import { addItemToCart } from '../addItemToCart';
import { useMigrationStatus } from '../useMigrationStatus';
import type { AddItemToCart } from './types';
import { useConvertCTCart } from './useConvertCTCart';
import { useCTCartData } from './useCTCartData';
import { useMonolithCartRefetch } from './useMonolithCartRefetch';

/**
 * Hook to add items to the cart.
 * @returns An object containing the `addItemToCart` function and the current `loading` and `error` states.
 */
export const useAddItemToCart = () => {
  const { refetchCart, shopCartData, updateCartMutation } = useCTCartData();
  const country = getCountryCart();
  const [createCartMutation] = useCreateShopCartJupiterMutation({
    refetchQueries: [{ query: GetShopCartJupiterDocument, variables: { country } }],
    awaitRefetchQueries: true,
  });
  const setIsCartLoading = useSetIsCartLoading();
  const openCartPanel = useOpenCartPanel();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { convertCTCart } = useConvertCTCart(setError);
  const toggleStatus = useMigrationStatus();
  const { track } = useTrackWithDriftAndSegment();
  const trackCfuAnalytics = useAddCfuToCartAnalytics();
  const { isReferralSession } = useIsReferralSession();

  const { data: monolithCartData, refetchMonolithCart } = useMonolithCartRefetch();

  /**
   * Adds an item to the cart using the provided parameters.
   * @param params - The parameters required to add the item.
   */
  const draftAddItemsToCart = ({
    type,
    sku,
    bundleObject,
    cfuPackage,
    callBack,
    item,
    quantity,
  }: AddItemToCart) => {
    return addItemToCart({
      bundleObject,
      callBack,
      cfuPackage,
      convertCTCart,
      createCartMutation,
      monolithCartData,
      openCartPanel,
      refetchCTCart: refetchCart,
      refetchMonolithCart,
      setError,
      setIsCartLoading,
      setLoading,
      shopCartData,
      sku,
      type,
      updateCartMutation,
      toggleStatus,
      track,
      item,
      isReferralSession,
      trackCfuAnalytics,
      quantity,
    });
  };

  return { addItemToCart: draftAddItemsToCart, result: { loading, error } };
};
